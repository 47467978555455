<template>
  <section class="mobile-tabs" :class="[type, size]" data-t="mobile-tabs">
    <StSlider
      class="mobile-tab-list"
      list-tag="ul"
      :selected-ids="[modelValue]"
      :items-gap="type === 'row' ? 0 : 8"
      disable-arrows
      @select="handleSelect"
    >
      <StSliderItem
        v-for="item in tabItems"
        v-slot="{ selected }"
        :key="item.id"
        :ref="(el) => (tabRefs[item.id] = el as ComponentPublicInstance)"
        tag="li"
        class="mobile-tab"
        :data-t="`mobile-tab-item-${item.id}`"
      >
        <div
          class="mobile-tabs-wrapper"
          :title="item.title"
          :class="[{ selected: selected }]"
          :data-t="`mobile-tab-${item.id}`"
        >
          <div class="icon-container">
            <StIcon
              v-if="item.iconLink"
              :icon-link="item.iconLink"
              class="mobile-tab-icon"
              :size="iconSize"
            />
            <StIcon
              v-else-if="item.icon"
              class="mobile-tab-icon"
              :name="item.icon"
              :size="iconSize"
            />
            <StCounter
              v-if="item.counter"
              :label="item.counter"
              bg-color="orange"
              color="white"
              size="xxs"
              class="tab-counter"
            />
          </div>
        </div>
        <p class="mobile-tab-title" data-t="mobile-tab-title">
          {{ item.title }}
        </p>
      </StSliderItem>
    </StSlider>
  </section>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import { useMobileLayout } from '../../composables'
import type { SliderItemEmitSelectedParams } from '../StSlider/types'
import StCounter from '../StCounter/StCounter.vue'
import type { MobileTab } from './types'

const { headerColor, setHeaderColor } = useMobileLayout()

const props = withDefaults(
  defineProps<{
    modelValue: string | number
    tabItems: MobileTab[]
    type?: 'row' | 'column'
    size?: 's' | 'm'
    scrollAfterInit?: boolean
  }>(),
  {
    type: 'column',
    size: 's',
    scrollAfterInit: false,
  },
)
const emit =
  defineEmits<(e: 'update:modelValue', value: string | number) => void>()

const iconSize = computed(() => (props.type === 'row' ? 20 : 24))

const activeTab = computed(() =>
  props.tabItems.find(({ id }) => id === props.modelValue),
)
const tabRefs = ref<Record<string, ComponentPublicInstance | null>>({})
const activeTabElement = computed(() =>
  activeTab.value ? tabRefs.value[activeTab.value.id] : null,
)

function scrollIntoView() {
  if (activeTabElement.value) {
    activeTabElement.value.$el?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    })
  }
}
async function handleSelect(params: SliderItemEmitSelectedParams) {
  const { itemId } = params
  const currentTab = props.tabItems.find(({ id }) => id === itemId)

  if (currentTab) {
    emit('update:modelValue', itemId)
  }

  await nextTick()
  scrollIntoView()
}

watchEffect(() => {
  if (activeTab.value) {
    setHeaderColor(activeTab.value.actionColor)
  }
})

watch(
  () => activeTabElement.value,
  async (newValue, oldValue) => {
    if (newValue && !oldValue && props.scrollAfterInit) {
      await delay(300)
      scrollIntoView()
    }
  },
  {
    immediate: true,
  },
)
</script>

<style scoped>
.mobile-tab-list {
  display: flex;
  width: 100%;
}

.mobile-tab-title {
  overflow: hidden;

  margin: 0;

  font: var(--mobile-text-medium);
  color: var(--text-secondary);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-tab {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050, 4px);
  align-items: center;

  min-width: 60px;
  padding: var(--spacing-050) var(--spacing-125);

  border-radius: var(--border-radius-150);
}

.mobile-tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  color: var(--text-secondary);

  &.selected {
    color: var(--text-primary);

    + .mobile-tab-title {
      color: var(--text-primary);
    }
  }
}

.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-counter {
  position: absolute;
  bottom: 12px;
  left: 13px;
}

.mobile-tabs {
  display: flex;
  padding: var(--spacing-100);
  padding-right: 0;
  background-color: v-bind(headerColor);

  &.row {
    .mobile-tabs-wrapper {
      width: auto;
    }

    .mobile-tab-title {
      font: var(--mobile-headline-semibold);
    }

    .mobile-tab {
      cursor: pointer;
      flex-direction: row;
      gap: var(--spacing-075);
      padding: var(--spacing-050) var(--spacing-100);

      &:hover {
        .mobile-tab-title {
          color: var(--text-primary);
        }
      }
    }

    &.m {
      font: var(--desktop-text-md-semibold);

      .mobile-tab:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
